import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'

export default function ResourceDetails({ data }) {
    const { html } = data.markdownRemark
    const { title, author, date } = data.markdownRemark.frontmatter
    
    return (
        <Layout>
            <div className="container pb-5 content">
                <h2 className="title has-text-primary">{title}</h2>
                {/* <p className="subtitle has-text-light">{"By: " + author + " , " + date}</p> */}
                <div className="has-text-light pb-5 content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>
    )
}


export const query = graphql`
    query ResourceDetails($slug: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
        html
        frontmatter {
            title
            author
            date(formatString: "MMMM DD, YYYY")
        }
        }
    }
`


